@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Hind+Madurai:wght@300;400;500;600;700&display=swap");

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #EEF2FB;
  color: #0A0A0A;
  text-align: center;
  padding: 2rem;
}

.intro-text  {
  user-select: none;
}

.intro-text h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 4rem;  
  margin-bottom: 0;
}

.intro-text h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 2.5rem; 
  margin-bottom: 1rem;
}

.intro-text p {
  font-family: "Hind Madurai", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 2rem;
  line-height: 1.6; 
  max-width: 600px; 
}


