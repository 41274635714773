/* Contact.css */

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  /* background: linear-gradient(135deg, #0e141b, #1abc9c); */
  background: #1F1F1F;
  min-height: 100vh;
  color: #fbfdff;
  text-align: center;
}

.contact-container h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 3rem;
  margin-bottom: 2rem;
  user-select: none;
}

.socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.socials a {
  text-decoration: none;
}

.social-item {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 1rem;
  transition: transform 0.3s ease, background 0.3s ease;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: #fbfdff;
}

.social-item:hover {
  transform: translateY(-10px);
  background: rgba(255, 255, 255, 0.2);
}

.social-item img {
  width: 24px;
  height: 24px;
  margin-left: auto;
}

footer {
  margin-top: 2rem;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.7);
}
